import './bootstrap';
import '../css/app.scss';

import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import {i18nVue} from 'laravel-vue-i18n';
import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/vue';
import 'tippy.js/dist/tippy.css';
import VueTippy from 'vue-tippy';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || '711OS';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const application = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(i18nVue, {
                resolve: async (lang) => {
                    const langs = import.meta.glob('../../lang/*.json');
                    try {
                        return await langs[`../../lang/${lang}.json`]();
                    } catch (error) {
                        // do nothing
                    }
                },
            })
            .use(ZiggyVue, Ziggy)
            .use(VueTippy, {
                defaultProps: {
                    placement: 'top',
                    animateFill: false,
                    hideOnClick: false,
                    trigger: 'mouseenter',
                },
            });

        Sentry.init({
            app: application,
            dsn: window.os711config.SENTRY_DNS,
            initialScope: {
                user: {
                    id: window.os711config.USERNAME,
                    email: window.os711config.USERNAME,
                },
            },
            environment: 'production',
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            trackComponents: true,
            logErrors: true,
            integrations: [new BrowserTracing(), new Sentry.Replay()],
        });

        application.mount(el);

        return application;
    },
    progress: {
        color: '#4B5563',
    },
});
