<template>
    <div class="relative mt-2" :class="$attrs.class">
        <label class="mb-1" v-if="label" :for="props.id">{{ label }}</label>
        <div class="relative">
            <input
                ref="input"
                v-bind="{...$attrs, class: null}"
                :class="[
                    {'border-error bg-error': error},
                    'block w-full h-11 py-2 px-3.5 pr-8 disabled:text-gray-700 border-gray bg-transparent focus:border-brand focus:ring-brand rounded-md text-dark placeholder:text-gray',
                ]"
                :value="modelValue || ''"
                :type="state.showPassword ? 'text' : type"
                :id="props.id"
                :placeholder="computedPlaceholder"
                @input="handleInput"
                @focus="focusInput"
            />
            <a
                href="#"
                v-if="type === 'password'"
                class="absolute btn btn-icon inset-y-0 right-0 flex items-center pr-3"
                tabindex="-1"
                aria-hidden="true"
                @click="togglePassword"
            >
                <EyeSlashIcon v-if="state.showPassword" class="h-5 w-5" aria-hidden="true" />
                <EyeIcon v-else class="h-5 w-5" aria-hidden="true" />
            </a>
            <button
                v-if="type === 'search'"
                @click="handleClearBtnClick"
                class="absolute btn btn-icon inset-y-0 right-0 flex items-center pr-3"
            >
                <MagnifyingGlassIcon
                    v-if="!hasValueEntered"
                    class="icon-search h-5 w-5 text-gray stroke-2"
                    aria-hidden="true"
                />
                <XMarkIcon
                    v-if="hasValueEntered"
                    class="icon-clear h-5 w-5 text-gray stroke-2"
                    aria-hidden="true"
                />
            </button>
        </div>
        <p v-if="message" class="text-gray text-sm mt-2">{{ message }}</p>
        <p v-if="error && !hideErrorMessage" class="text-sm text-red-600 mt-2">{{ error }}</p>
    </div>
</template>
<script setup lang="ts">
import {onBeforeUnmount, onMounted, reactive, ref, watch, watchEffect} from 'vue';
import {v4 as uuid} from 'uuid';
import {EyeIcon, EyeSlashIcon} from '@heroicons/vue/24/solid';
import {MagnifyingGlassIcon} from '@heroicons/vue/24/outline';
import {XMarkIcon} from '@heroicons/vue/24/outline';
import {trans} from 'laravel-vue-i18n';

interface Props {
    modelValue:
        | string
        | number
        | null
        | undefined
        | {get: () => string | number; set: (value: string) => void};
    label?: string;
    message?: string;
    error?: string;
    type?: string;
    id?: string;
    placeholder?: string;
    hideErrorMessage?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    id: () => `text-input-${uuid()}`,
    type: 'text',
    hideErrorMessage: false,
});

const emit = defineEmits(['update:modelValue', 'focus']);

const input = ref();
const hasValueEntered = ref(false);
const state = reactive({showPassword: false});

const computedPlaceholder = ref(props.placeholder);

watchEffect(() => {
    if (!props.placeholder) {
        computedPlaceholder.value = `${trans('ui.form.enter')}${
            props.label ? ` ${props.label}`.toLowerCase() : ''
        }`;
    }
});

watch(
    () => props.placeholder,
    () => (computedPlaceholder.value = props.placeholder),
);

const togglePassword = (e: MouseEvent) => {
    state.showPassword = !state.showPassword;
    e.preventDefault();
};

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }

    iconToggle();

    input.value.addEventListener('input', iconToggle);
});

onBeforeUnmount(() => {
    input.value.removeEventListener('input', iconToggle);
});

const focusInput = () => {
    emit('focus');
};

const iconToggle = () => {
    hasValueEntered.value = input.value.value.length > 0;
};

const handleInput = (event: Event) => {
    const input = event.target as HTMLInputElement;
    emit('update:modelValue', input.value);
};

const handleClearBtnClick = () => {
    input.value.value = '';
    iconToggle();

    const e = new Event('input');
    input.value.dispatchEvent(e);
};

defineExpose({focus: () => input.value.focus()});
</script>
